<template>
  <v-container>
    <PageHeader :title="title" :items="items" />

    <loading-flux :value="loading"></loading-flux>

    <b-alert
      :show="segundosMensajeActualizacion"
      dismissible
      :variant="mensaje.variant"
      @dismissed="segundosMensajeActualizacion = 0"
      @dismiss-count-down="actualizarSegundosMensajeActualizacion"
    >
      {{ mensaje.texto }}
    </b-alert>

    <div class="row">
      <div class="col-12 mb-5">
        <div class="card" v-if="renderComponent">
          <div class="card-body">
            <div class="d-flex">
              <div class="card-title col-auto me-auto">
                Información General del Proyecto
              </div>
              <div class="col-auto text-md-end">
                <button
                  v-if="rolModuloPermiso.escritura"
                  class="btn btn-outline-primary"
                  @click="abrirModalEditar"
                >
                  Editar Datos
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-project-diagram icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Código Proyecto
                    </div>
                    <div>
                      {{ proyectoSeleccionado.codigo_proyecto }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-tachometer-alt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      {{ $route.query.sap_project ? 'Etapas' : 'Etapa' }}
                    </div>
                    <div v-if="$route.query.sap_project">
                      <div v-for="stage in proyectoSeleccionado.sap_stages" :key="stage.id">                        
                        <span>
                          {{stage.stage.name}}
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      {{ proyectoSeleccionado.nombre_etapa_proyecto }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-map-pin icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Region
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_region }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-cog icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tipo Proyecto
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_tipo_proyecto }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-money-bill icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Monto
                    </div>
                    <div>
                      {{ formatCurrency(proyectoSeleccionado.monto_proyecto) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-donate icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tipo Venta
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_tipo_venta }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- </div> -->
              <!-- <div class="row mt-4"> -->
            </div>

            <div class="row ">
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-map-marked-alt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Dirección
                    </div>
                    <div>
                      {{ proyectoSeleccionado.direccion }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-city icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Comuna
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_comuna }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-solar-panel icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tablero Personalizado
                    </div>
                    <div>
                      {{ proyectoSeleccionado.panel_especial
                          ? proyectoSeleccionado.panel_especial
                          : 'Sin Tablero'
                      }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-5">
        <div class="card" v-if="renderComponent">
          <div class="card-body">
            <div class="d-flex">
              <div class="card-title col-auto me-auto">
                Información Técnica del Proyecto
              </div>
              <div class="col-auto text-md-end">
                <button
                  v-if="rolModuloPermiso.escritura"
                  class="btn btn-outline-primary"
                  @click="abrirModalEditarInformacionTecnica"
                >
                  Editar Datos
                </button>
              </div>
            </div>
            <!-- <p class="card-title-desc"></p> -->
            <div class="row">
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-bolt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Potencia Peak [W]
                    </div>
                    <div>
                      {{ proyectoSeleccionado.potencia_peak }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-charging-station icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Potencia AC [W]
                    </div>
                    <div>
                      {{ proyectoSeleccionado.potencia_ac }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="dripicons-battery-full icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Capacidad Baterías [KWh]
                    </div>
                    <div>
                      {{ proyectoSeleccionado.capacidad_baterias }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="row mt-4"> -->
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="mdi mdi-tools icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tipo Instalacion
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_tipo_instalacion }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-plug icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Fase Instalacion
                    </div>
                    <div>
                      {{ proyectoSeleccionado.fase_instalacion }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-user-cog icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Tipo Cliente
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_tipo_cliente }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="row mt-4"> -->
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-list-alt icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Normativa
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_normativa }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="far fa-address-card icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Cliente Electrico
                    </div>
                    <div>
                      {{ proyectoSeleccionado.cliente_electrico }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-4 mt-4" v-if="false">
                <div>
                  <i class="fas fa-sun icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      App. Flex energia
                    </div>
                    <div>
                      {{ proyectoSeleccionado.nombre_app_flex_energetica }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-4">
                <div>
                  <i class="fas fa-signal icono"></i>
                  <div class="contenedor-textos">
                    <div class="text-muted">
                      Capacidad Empalme [A]
                    </div>
                    <div>
                      {{ proyectoSeleccionado.capacidad_empalme }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="rolModuloPermiso.escritura">
      <div class="boton-lugar-instalacion col-lg-auto">
        <button class="btn btn-success" @click="abrirModalNuevoWizar">
          <i class="mdi mdi-plus me-1"></i>
          Crear Lugar Instalación
        </button>
      </div>
      <div class="boton-lugar-instalacion  col-lg-auto">
        <button
          class="btn btn-primary"
          @click="crearLugarInstalacionInformacionProyecto"
        >
          <i class="mdi mdi-plus me-1"></i>
          Replicar Infomacion Proyecto
        </button>
      </div>
      <div
        class="boton-lugar-instalacion  col text-md-end"
        v-if="lugaresInstalacion.length > 1"
      >
        <button class="btn btn-primary w-lg" @click="exportarExcel()">
          <i class="bx bx-download me-1"></i>
          Exportar
        </button>
      </div>
    </div>
    <span style="opacity: 0;">--</span>

    <div class="card mt-5">
      <div class="card-header pb-0">
        <h4 class="float-left">
          Lugares de instalación
        </h4>
        <h5 class="float-left pt-1 ml-5">
          <small>Cantidad de lugares: {{ lugaresInstalacion.length }}</small>
        </h5>
      </div>

      <div class="card-body card-body-lugares">
        <div class="row">
          <div
            v-for="lugarInstalacion in lugaresInstalacion"
            :key="lugarInstalacion.lugar_instalacion_id"
            class="proyecto-contenedor col-12 col-lg-6 mt-3"
          >
            <!-- @click="abrirMenu(lugarInstalacion)" -->
            <div class="card container">
              <div class="card-body p-0 row">
                <label
                  @mouseover="
                    mostrarBotones(
                      `botones-${lugarInstalacion.lugar_instalacion_id}`
                    )
                  "
                  @mouseleave="
                    ocultarBotones(
                      `botones-${lugarInstalacion.lugar_instalacion_id}`
                    )
                  "
                  class="label-proyecto-imagen"
                  :for="
                    `input-file-imagen-${lugarInstalacion.lugar_instalacion_id}`
                  "
                >
                  <div class="proyecto-imagen">
                    <div
                      :id="`botones-${lugarInstalacion.lugar_instalacion_id}`"
                      class="contenedor-botones-lugar d-flex align-items-center justify-content-center"
                    >
                      <button
                        @click="
                          abrirArchivo(
                            `input-file-imagen-${lugarInstalacion.lugar_instalacion_id}`
                          )
                        "
                        class="btn btn-primary btn-sm me-2"
                      >
                        Editar Imagen
                      </button>
                    </div>
                    <img
                      :src="urlImagen(lugarInstalacion.imagen)"
                      @error="imageUrlError"
                    />
                    <input
                      v-if="rolModuloPermiso.escritura"
                      :id="
                        `input-file-imagen-${lugarInstalacion.lugar_instalacion_id}`
                      "
                      class="input-file-imagen"
                      type="file"
                      name="fileDocumento"
                      accept=".png, .jpg, .jpeg,.bmp"
                      @change="onFileChange($event, lugarInstalacion)"
                    />
                  </div>
                </label>
                <div class="proyecto-cuerpo p-0">
                  <div
                    class="contenedor-menu-movil-lugares-instalacion"
                    @click="abrirMenu(lugarInstalacion)"
                  >
                    <i class="mdi mdi-dots-horizontal"></i>
                  </div>

                  <div class="contenedor-menu-escritorio-lugares-instalacion mt-3">
                    <MenuEscritorio :lugarInstalacion="lugarInstalacion" />
                  </div>

                  <h3
                    class="my-3 pt-3 text-dark titulo-lugar-instalacion"
                    style="font-size: 18px"
                  >
                    {{ lugarInstalacion.nombre_proyecto }}
                  </h3>
                  <div class="proyecto-cuerpo-direccion-contenedor text-muted my-3 font-size-5 container p-0 m-0">
                    <div class="row mt-5 mx-0">
                      <div class="col-1 p-0 mt-4" v-if="lugarInstalacion.direccion">
                        <i class="text-muted bx bx-map h3"></i>
                      </div>
                    <div class="col-11 p-0 mt-4">
                      <span class="">{{
                        lugarInstalacion.direccion
                      }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class=""></div> -->
                  <div class="proyecto-pie">
                    <ul class="list-inline flex-row justify-content-between mb-0 p-0">
                      <li
                        v-if="
                          rolModuloPermisoSolicitud &&
                            rolModuloPermisoSolicitud.lectura
                        "
                        class="list-inline-item"
                        @click="verMateriales(lugarInstalacion)"
                      >
                        <i class="mdi mdi-tools h4 me-1"></i>
                        <span>Materiales</span>
                      </li>
                      <span
                        class="menu-separador"
                        v-if="
                          rolModuloPermisoImagenes &&
                            rolModuloPermisoImagenes.lectura
                        "
                        >|</span
                      >
                      <li
                        v-if="
                          rolModuloPermisoImagenes &&
                            rolModuloPermisoImagenes.lectura
                        "
                        class="list-inline-item"
                        @click="verGaleriaImagenes(lugarInstalacion)"
                      >
                        <i class="bx bx-images h4 me-1"></i>
                        <span>Galeria</span>
                      </li>
                      <span
                        class="menu-separador"
                        v-if="
                          rolModuloPermisoDocumentos &&
                            rolModuloPermisoDocumentos.lectura
                        "
                        >|</span
                      >
                      <li
                        v-if="
                          rolModuloPermisoDocumentos &&
                            rolModuloPermisoDocumentos.lectura
                        "
                        class="list-inline-item"
                        @click="verDocumentos(lugarInstalacion)"
                      >
                        <i class="bx bx-file h4 me-1"></i>
                        <span>Documentos</span>
                      </li>
                      <!-- <span
                        class="menu-separador"
                        v-if="
                          rolModuloPermisoGantt && rolModuloPermisoGantt.lectura
                        "
                        >|</span
                      > -->
                      <!-- <li
                        class="list-inline-item"
                        v-if="
                          rolModuloPermisoGantt && rolModuloPermisoGantt.lectura
                        "
                        @click="verGantt(lugarInstalacion)"
                      >
                        <i class="bx bx-calendar h4 me-1"></i>
                        <span>Gantt</span>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-menu-lugares-instalacion"
      content-class="modal-menu-modulos-lugares-instalacion-content"
      body-class="modal-menu-modulos-lugares-instalacion-body"
      centered
      title="Center modal"
      title-class="font-18"
      hide-header
      hide-footer
    >
      <MenuMovil :lugarInstalacion="lugarInstalacion" />
    </b-modal>
    <b-modal v-model="mostrarModalWizar" hide-header hide-footer size="lg">
      <WizarCrearLugarInstalacion @cerrarWizar="cerrarWizar" />
    </b-modal>

    <b-modal
      v-model="mostrarModalEditarProyecto"
      title="Información General"
      id="modal-menu-editar-informacion"
      hide-footer
      size="lg"
    >
      <loading-flux :value="loading"></loading-flux>

      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="codigoProyecto">Codigo Proyecto*</label>
              <input
                id="codigoProyecto"
                v-model="$v.form.codigoProyecto.$model"
                type="text"
                class="form-control"
                placeholder="Codigo Proyecto"
                :disabled="esEditar"
                :class="{
                  'is-invalid': submitted && $v.form.codigoProyecto.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.codigoProyecto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="nombreProyecto">Nombre Proyecto*</label>
              <input
                id="nombreProyecto"
                v-model="$v.form.nombreProyecto.$model"
                type="text"
                class="form-control"
                placeholder="Nombre Proyecto"
                :class="{
                  'is-invalid': submitted && $v.form.nombreProyecto.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.nombreProyecto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="etapaProyecto">Etapa Proyecto*</label>
              <multiselect
                v-model="$v.form.etapaProyecto.$model"
                :options="etapasProyecto"
                placeholder="Seleccionar Etapa"
                value="etapa_proyecto_id"
                label="nombre_etapa_proyecto"
                :searchable="true"
                :loading="etapasProyectoCargando"
                class="helo"
              ></multiselect>
            </div>
          </div>
          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="subEtapaProyecto">Sub Etapa Proyecto*</label>
              <multiselect
                v-model="$v.form.subEtapaProyecto.$model"
                :options="subEtapasProyecto"
                placeholder="Seleccionar Sub Etapa"
                value="sub_etapa_proyecto_id"
                label="nombre_sub_etapa_proyecto"
                :searchable="true"
                :loading="subEtapasProyectoCargando"
                class="helo"
              ></multiselect>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="montoProyecto">Monto</label>
              <input
                id="montoProyecto"
                v-model="$v.form.montoProyecto.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Monto Proyecto"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoProyecto">Tipo Proyecto*</label>
              <multiselect
                v-model="$v.form.tipoProyecto.$model"
                :options="tipoProyectos"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_proyecto_id"
                label="nombre_tipo_proyecto"
                :searchable="true"
                :loading="tipoProyectosCargando"
                open-direction="bottom"
                class="helo"
              ></multiselect>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoVenta">Tipo Venta*</label>
              <multiselect
                v-model="$v.form.tipoVenta.$model"
                :options="tipoVentas"
                placeholder="Seleccionar Tipo Venta"
                value="tipo_venta_id"
                label="nombre_tipo_venta"
                :searchable="true"
                :loading="tipoVentasCargando"
                class="helo"
              ></multiselect>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="direccion">Dirección*</label>
              <input
                id="direccion"
                v-model="$v.form.direccion.$model"
                type="text"
                class="form-control"
                placeholder="Dirección"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="region">Region*</label>
              <multiselect
                @input="obtenerComunasRegionProyecto()"
                v-model="$v.form.region.$model"
                :options="regiones"
                placeholder="Seleccionar Region"
                value="codigo"
                label="nombre"
                :searchable="true"
                :loading="regionesCargando"
                class="helo"
              ></multiselect>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comuna">Comuna*</label>
              <multiselect
                v-model="$v.form.comuna.$model"
                :options="comunasRegion"
                placeholder="Seleccionar Comuna"
                value="codigo"
                label="nombre"
                :searchable="true"
                class="helo comuna"
                :loading="comunasRegionCargando"
              ></multiselect>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comuna">Tablero Personalizado</label>
              <multiselect
                v-model="$v.form.panel_especial.$model"
                :options="specialPanels"
                placeholder="Seleccionar Tablero Personalizado"
                :searchable="true"
                class="helo comuna"
                @input="openModalInputPanelIfOptionIsOther"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModalEditarProyecto = false"
            >Cerrar</b-button
          >
          <b-button type="submit" variant="success" class="ms-1"
            >Guardar</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="mostrarModalEditarInformacionTecnica"
      title="Información Técnica"
      hide-footer
      size="lg"
    >
      <loading-flux :value="loading"></loading-flux>

      <form @submit.prevent="submitInformacionTecnica">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaPeak">Potencia Peak [W]</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">W</span>
                </div>
                <input
                  id="potenciaPeak"
                  v-model="$v.formInformacionTecnica.potenciaPeak.$model"
                  type="number"
                  step="0.001"
                  class="form-control"
                  placeholder="Potencia Peak"
                  :class="{
                    'is-invalid':
                      submitted && $v.formInformacionTecnica.potenciaPeak.$error,
                  }"
                />
              </div>
              <div
                v-if="
                  submitted && !$v.formInformacionTecnica.potenciaPeak.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaAC">Potencia AC [W]</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">W</span>
                </div>
                <input
                  id="potenciaAC"
                  v-model="$v.formInformacionTecnica.potenciaAC.$model"
                  type="number"
                  step="0.001"
                  class="form-control"
                  placeholder="Potencia AC"
                  :class="{
                    'is-invalid':
                      submitted && $v.formInformacionTecnica.potenciaAC.$error,
                  }"
                />
              </div>
              <div
                v-if="
                  submitted && !$v.formInformacionTecnica.potenciaAC.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadBaterias">Capacidad Baterías [KWh]</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">KWh</span>
                </div>
                <input
                  id="capacidadBaterias"
                  v-model="$v.formInformacionTecnica.capacidadBaterias.$model"
                  type="number"
                  step="0.001"
                  class="form-control"
                  placeholder="Capacidad Bateria"
                  :class="{
                    'is-invalid':
                      submitted &&
                      $v.formInformacionTecnica.capacidadBaterias.$error,
                  }"
                />
              </div>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.capacidadBaterias.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoInstalacion">Tipo Instalación*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.tipoInstalacion.$model"
                :options="tipoInstalaciones"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_instalacion_id"
                label="nombre_tipo_instalacion"
                :searchable="true"
                :loading="tipoInstalacionesCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.formInformacionTecnica.tipoInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.tipoInstalacion.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="faseInstalacion">Fase Instalacion*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.faseInstalacion.$model"
                :options="fasesInstalacion"
                placeholder="Seleccionar Fase"
                value="value"
                label="label"
                :searchable="true"
                :loading="fasesInstalacionCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.formInformacionTecnica.faseInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.faseInstalacion.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoCliente">Tipo Cliente*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.tipoCliente.$model"
                :options="tipoClientes"
                placeholder="Seleccionar Tipo Cliente"
                value="tipo_cliente_id"
                label="nombre_tipo_cliente"
                :searchable="true"
                :loading="tipoClientesCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted && $v.formInformacionTecnica.tipoCliente.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted && !$v.formInformacionTecnica.tipoCliente.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="normativa">Normativa*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.normativa.$model"
                :options="normativas"
                placeholder="Seleccionar Normativa"
                value="normativa_id"
                label="nombre_normativa"
                :searchable="true"
                :loading="normativasCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted && $v.formInformacionTecnica.normativa.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted && !$v.formInformacionTecnica.normativa.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="clienteElectrico">Cliente Electrico*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.clienteElectrico.$model"
                :options="clientesElectrico"
                placeholder="Seleccionar Cliente Electrico"
                value="value"
                label="label"
                :searchable="true"
                :loading="clientesElectricoCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.formInformacionTecnica.clienteElectrico.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.clienteElectrico.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="appFlexEnergetica">App Flex Energetica*</label>
              <multiselect
                v-model="$v.formInformacionTecnica.appFlexEnergetica.$model"
                :options="appFlexEnergeticas"
                placeholder="Seleccionar App Flex Energetica"
                value="app_flex_energetica_id"
                label="nombre_app_flex_energetica"
                :searchable="true"
                :loading="clientesElectricoCargando"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.formInformacionTecnica.appFlexEnergetica.$error,
                }"
              ></multiselect>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.appFlexEnergetica.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadEmpalme">Capacidad Empalme [A]</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">A</span>
                </div>
                <input
                  id="capacidadEmpalme"
                  v-model="$v.formInformacionTecnica.capacidadEmpalme.$model"
                  type="number"
                  step="0.001"
                  class="form-control"
                  placeholder="Capacidad Empalme"
                  :class="{
                    'is-invalid':
                      submitted &&
                      $v.formInformacionTecnica.capacidadEmpalme.$error,
                  }"
                />
              </div>
              <div
                v-if="
                  submitted &&
                    !$v.formInformacionTecnica.capacidadEmpalme.required
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button
            variant="light"
            @click="mostrarModalEditarInformacionTecnica = false"
            >Cerrar</b-button
          >
          <b-button type="submit" variant="success" class="ms-1"
            >Guardar</b-button
          >
        </div>
      </form>
    </b-modal>
  </v-container>
</template>

<script>
import PageHeader from "@/components/page-header";
import MenuMovil from "@/components/widgets/menu/MovilWidget";
import MenuEscritorio from "@/components/widgets/menu/DesktopWidget";
import WizarCrearLugarInstalacion from "@/components/widgets/lugarInstalacion/wizar-crear-lugar-instalacion";
import appConfig from "@/app.config";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { projectData } from "./data-projects";
import {
  lugarInstalacionMethods,
} from "@/state/helpers";
import { etapaProyectoMethods } from "@/state/helpers";
import {
  subEtapaProyectoMethods,
} from "@/state/helpers";
import { tipoProyectoMethods } from "@/state/helpers";
import { tipoVentaMethods } from "@/state/helpers";
import { localizacionMethods } from "@/state/helpers";
import { tipoClienteMethods} from "@/state/helpers";
import {
  tipoInstalacionMethods,
} from "@/state/helpers";
import { normativaMethods } from "@/state/helpers";
import {
  appFlexEnergeticaMethods,
} from "@/state/helpers";
import { authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import moment from "moment";
moment.locale("es");
import { read, utils, writeFileXLSX } from "xlsx";
import { mapActions, mapGetters } from 'vuex';
/**
 * lugarInstalacion
 */
export default {
  name: "LugaresInstalacion",
  mixins: [validationMixin],
  page: {
    title: `Lugares Instalación`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    MenuMovil,
    MenuEscritorio,
    WizarCrearLugarInstalacion,
    Multiselect,
  },
  data() {
    return {
      mensaje: { variant: "", texto: "" },
      loading: false,
      lugarInstalacion: {},
      codigoProyecto: "",
      lugaresInstalacion: [],
      projectData: projectData,
      title: "Lugares Instalación",
      items: [
        {
          text: "Proyectos",
          href: `/proyectos/proyectos`,
        },
        {
          text: "Lugares Instalacion",
          active: true,
        },
      ],
      rolModuloPermiso: {},
      rolModuloPermisoGantt: {},
      rolModuloPermisoEliminarLugar: {},
      rolModuloPermisoImagenes: {},
      rolModuloPermisoSolicitud: {},
      rolModuloPermisoDocumentos: {},

      proyectoSeleccionado: {
        sap_stages: []
      },
      etapasProyecto: [],
      etapasProyectoCargando: false,
      subEtapasProyecto: [],
      subEtapasProyectoCargando: false,
      esEditar: false,
      submitted: false,
      mostrarModalEditarProyecto: false,
      form: {
        codigoProyecto: null,
        nombreProyecto: null,
        montoProyecto: null,
        etapaProyecto: null,
        fechaCreacion: null,
        imagen: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        subEtapaProyecto: null,
        direccion: null,
        panel_especial: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
      },
      formInformacionTecnica: {
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      filtroEtapas: [],
      etapaProyecto: null,
      // subEtapaProyecto: null,
      proyectosFiltrados: [],
      proyectosFiltradosEncontrados: [],
      renderComponent: true,
      renderComponentImage: true,
      tipoProyectos: [],
      tipoVentas: [],
      regiones: [],
      comunasRegion: [],
      zonas: [
        { value: "Norte", label: "Norte" },
        { value: "Centro", label: "Centro" },
        { value: "Sur", label: "Sur" },
        { value: "Insular", label: "Insular" },
      ],
      estados: [
        { value: "Activo", label: "Activo" },
        { value: "Cerrado", label: "Cerrado" },
        { value: "Detenido", label: "Detenido" },
        { value: "Nuevo", label: "Nuevo" },
        { value: "Terminado", label: "Terminado" },
      ],

      tipoProyectosCargando: false,
      tipoVentasCargando: false,
      regionesCargando: false,
      comunasRegionCargando: false,

      tipoInstalaciones: [],
      fasesInstalacion: [
        { value: "MONOFASICO", label: "MONOFASICO" },
        { value: "TRIFASICO", label: "TRIFASICO" },
        { value: "NO APLICA", label: "NO APLICA" },
      ],
      tipoClientes: [],
      clientesElectrico: [
        { value: "Cliente Regulado", label: "Cliente Regulado" },
        { value: "Cliente Libre", label: "Cliente Libre" },
        {
          value: "Desconectado/Aislado",
          label: "Desconectado/Aislado",
        },
      ],

      normativas: [],
      appFlexEnergeticas: [],

      tipoInstalacionesCargando: false,
      fasesInstalacionCargando: false,
      tipoClientesCargando: false,
      clientesElectricoCargando: false,
      normativasCargando: false,
      appFlexEnergeticasCargando: false,
      mostrarModalWizar: false,
      mostrarModalEditarInformacionTecnica: false,
      imagenParaSubir: null,
      verTodosLugares: false,
    };
  },
  validations: {
    form: {
      codigoProyecto: { required },
      nombreProyecto: { required },
      montoProyecto: {},
      etapaProyecto: {},
      imagen: {},
      tipoProyecto: { },
      tipoVenta: { },
      estado: { },
      subEtapaProyecto: {},
      direccion: { },
      panel_especial: {},
      region: { },
      comuna: { },
      latitud: {},
      longitud: {},
      zona: {},
    },
    formInformacionTecnica: {
      potenciaPeak: {},
      potenciaAC: {},
      capacidadBaterias: {},
      tipoInstalacion: { required },
      faseInstalacion: { required },
      tipoCliente: { required },
      clienteElectrico: { required },
      normativa: { required },
      appFlexEnergetica: {},
      capacidadEmpalme: {},
    },
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    let verTodosLugares = permiso.obtenerPermisos(this.user, "verTodosLugares");

    this.verTodosLugares = false;
    if (verTodosLugares != null) {
      this.verTodosLugares = true;
    }
    this.obtenerProyectoUsuario();
    this.obtenerLugaresInstalacionProyecto();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;

    let permisosGantt = permiso.obtenerPermisos(this.user, "gantt");
    this.rolModuloPermisoGantt = permisosGantt;

    let permisosEliminarLugar = permiso.obtenerPermisos(
      this.user,
      "eliminarLugarInstalacion"
    );
    this.rolModuloPermisoEliminarLugar = permisosEliminarLugar;

    let permisosImagenes = permiso.obtenerPermisos(
      this.user,
      "galeriaImagenes"
    );
    this.rolModuloPermisoImagenes = permisosImagenes;

    let permisosSolicitud = permiso.obtenerPermisos(
      this.user,
      "solicitudMaterial"
    );
    this.rolModuloPermisoSolicitud = permisosSolicitud;

    let permisoDocumentos = permiso.obtenerPermisos(
      this.user,
      "documentos"
    );
    this.rolModuloPermisoDocumentos = permisoDocumentos;

    //Stages (SAP)
    if(this.$route.query.sap_project){
      this.getAndSetStagesSapProject()
    }

  },
  methods: {
    ...lugarInstalacionMethods,
    ...proyectoMethods,
    ...etapaProyectoMethods,
    ...subEtapaProyectoMethods,
    ...tipoProyectoMethods,
    ...tipoVentaMethods,
    ...localizacionMethods,
    ...tipoClienteMethods,
    ...tipoInstalacionMethods,
    ...normativaMethods,
    ...appFlexEnergeticaMethods,
    ...mapActions({
      setNewSpecialPanel: 'project/setNewSpecialPanel',
      getSpecialPanels: "project/getSpecialPanels",
      getStagesFromProject: 'requestMaterialsSap/getStagesFromProject'
    }),
    async getAndSetStagesSapProject(){
      const response = await this.getStagesFromProject({
        project_code:this.codigoProyecto
      })
      this.proyectoSeleccionado.sap_stages = response.stages
    },
    eliminarLugar(lugar_instalacion_id) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-16",
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-light",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.value) {
            let lugar = {
              lugar_instalacion_id: lugar_instalacion_id,
              activo: 0,
            };
            this.actualizarLugarInstalacionActivo(lugar)
              .then((res) => {
                this.$router.go();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;
              });
            this.submitted = false;
          }
        });
    },
    eliminarProyectoLocal(proyecto_id) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-16",
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-light",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarProyecto(proyecto_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Proyecto eliminado correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerProyectosUsuario();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    mostrarBotones(botones) {
      document.getElementById(botones).classList.add("mostrar-botones");
      document.getElementById(botones).classList.remove("ocultar-botones");
    },
    ocultarBotones(botones) {
      document.getElementById(botones).classList.add("ocultar-botones");
      document.getElementById(botones).classList.remove("mostrar-botones");
    },
    abrirArchivo(id) {
      document.getElementById(id).click();
    },
    onFileChange(e, lugarInstalacion) {
      this.loading = true;
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        this.imagenParaSubir = file;
        reader.readAsDataURL(file);

        const lugar_instalacion_id = lugarInstalacion.lugar_instalacion_id;
        let formData = new FormData();
        formData.append("file", this.imagenParaSubir);

        formData.append(
          "lugar_instalacion",
          JSON.stringify({
            lugar_instalacion_id,
          })
        );

        this.actualizarLugarInstalacionImagen(formData)
          .then((res) => {
            this.$router.go();
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
        this.submitted = false;
      } else {
        alert("Sorry, FileReader API not supported");
        this.loading = false;
      }
    },
    exportarExcel() {
      if (this.verTodosLugares) {
        this.obtenerLugaresInstalacionExcel(this.$route.params.codigoProyecto)
          .then((res) => {
            let data = utils.json_to_sheet(res.body);
            const workbook = utils.book_new();
            const filename = "Lugares de instalación";
            utils.book_append_sheet(workbook, data, filename);
            writeFileXLSX(workbook, `${filename}.xlsx`);
          })
          .catch((error) => {});
      } else {
        this.obtenerLugaresInstalacionExcelContacto({
          codigoProyecto: this.$route.params.codigoProyecto,
          emailContacto: this.user.email,
        })
          .then((res) => {
            let data = utils.json_to_sheet(res.body);
            const workbook = utils.book_new();
            const filename = "Lugares de instalación";
            utils.book_append_sheet(workbook, data, filename);
            writeFileXLSX(workbook, `${filename}.xlsx`);
          })
          .catch((error) => {});
      }
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    crearLugarInstalacionInformacionProyecto(item) {
      const mensajeValeConsumo = Swal.mixin({
        customClass: {
          title: "font-size-16",
          text: "font-size-16",
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-light",
          input: "font-size-16",
          inputLabel: "font-size-16",
        },
        buttonsStyling: false,
      });
      mensajeValeConsumo
        .fire({
          title:
            "Crear el lugar de instalación con la misma información que el proyecto",
          text: "",
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Crear",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.replicarProyecto({
              codigo_proyecto: this.codigoProyecto,
            })
              .then((res) => {
                if (res.status == 201) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto =
                    "Lugar de instalación creado correctamente!!!";

                  this.obtenerLugaresInstalacionProyecto();
                }

                if (res.status == 208) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto = "El lugar de instalación ya existe!!!";
                }

                this.segundosMensajeActualizacion = this.segundos;

                this.loading = false;
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;
              });
          } else {
            // this.forceRerender()
          }
        });
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModalEditarProyecto = true;
      this.obtenerEtapaProyectoProyecto();
      // this.obtenerSubEtapaProyectoProyecto();
      this.obtenerTipoProyectosProyecto();
      this.obtenerTipoVentasProyecto();
      this.obtenerRegionesProyecto();

      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();
    },

    abrirModalEditar() {
      this.resetForm();
      this.obtenerEtapaProyectoProyecto();
      // this.obtenerSubEtapaProyectoProyecto();
      this.obtenerTipoProyectosProyecto();
      this.obtenerTipoVentasProyecto();
      this.obtenerRegionesProyecto();

      this.$v.form.codigoProyecto.$model = this.proyectoSeleccionado.codigo_proyecto;
      this.$v.form.nombreProyecto.$model = this.proyectoSeleccionado.nombre_proyecto;

      this.$v.form.nombreProyecto.$model = this.proyectoSeleccionado.nombre_proyecto;
      this.$v.form.montoProyecto.$model = this.proyectoSeleccionado.monto_proyecto;
      // this.$v.form.estado.$model = this.proyectoSeleccionado.estado;
      this.$v.form.latitud.$model = this.proyectoSeleccionado.latitud;
      this.$v.form.longitud.$model = this.proyectoSeleccionado.longitud;

      this.$v.form.direccion.$model = this.proyectoSeleccionado.direccion;
      this.$v.form.panel_especial.$model = this.proyectoSeleccionado.panel_especial;

      if (this.proyectoSeleccionado.estado != null) {
        this.$v.form.estado.$model = {
          value: this.proyectoSeleccionado.estado,
          label: this.proyectoSeleccionado.estado,
        };
      }

      if (this.proyectoSeleccionado.zona != null) {
        this.$v.form.zona.$model = {
          value: this.proyectoSeleccionado.zona,
          label: this.proyectoSeleccionado.zona,
        };
      }

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModalEditarProyecto = true;
      setTimeout(() => {
        document
          .getElementById("modal-menu-editar-informacion___BV_modal_content_")
          .removeAttribute("tabindex");
      }, 1000);
    },
    abrirModalEditarInformacionTecnica() {
      this.resetForm();

      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();

      this.$v.formInformacionTecnica.potenciaPeak.$model = this.proyectoSeleccionado.potencia_peak;
      this.$v.formInformacionTecnica.potenciaAC.$model = this.proyectoSeleccionado.potencia_ac;
      this.$v.formInformacionTecnica.capacidadBaterias.$model = this.proyectoSeleccionado.capacidad_baterias;
      this.$v.formInformacionTecnica.capacidadEmpalme.$model = this.proyectoSeleccionado.capacidad_empalme;

      if (this.proyectoSeleccionado.fase_instalacion != null) {
        this.$v.formInformacionTecnica.faseInstalacion.$model = {
          value: this.proyectoSeleccionado.fase_instalacion,
          label: this.proyectoSeleccionado.fase_instalacion,
        };
      }

      if (this.proyectoSeleccionado.cliente_electrico != null) {
        this.$v.formInformacionTecnica.clienteElectrico.$model = {
          value: this.proyectoSeleccionado.cliente_electrico,
          label: this.proyectoSeleccionado.cliente_electrico,
        };
      }

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModalEditarInformacionTecnica = true;
    },
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.proyectoSeleccionado = {
            ...this.proyectoSeleccionado,
            ...res.body,
          };
          this.getSpecialPanels({
            custom_panel: this.proyectoSeleccionado.panel_especial ?? undefined
          })
          this.forceRerender();
          this.title = `Proyecto: ${this.proyectoSeleccionado.nombre_proyecto}`;
        })
        .catch((error) => {});
    },
    obtenerNormativasProyecto() {
      this.normativasCargando = true;
      this.obtenerNormativas()
        .then((res) => {
          this.normativas = res.body;
          if (this.proyectoSeleccionado.normativa_id != null) {
            this.$v.formInformacionTecnica.normativa.$model = {
              normativa_id: this.proyectoSeleccionado.normativa_id,
              nombre_normativa: this.proyectoSeleccionado.nombre_normativa,
            };
          }
          this.normativasCargando = false;
        })
        .catch((error) => {
          this.normativasCargando = false;
        });
    },
    obtenerTipoInstalacionesProyecto() {
      this.tipoInstalacionesCargando = true;
      this.obtenerTipoInstalaciones()
        .then((res) => {
          this.tipoInstalaciones = res.body;
          if (this.proyectoSeleccionado.tipo_instalacion_id != null) {
            this.$v.formInformacionTecnica.tipoInstalacion.$model = {
              tipo_instalacion_id: this.proyectoSeleccionado
                .tipo_instalacion_id,
              nombre_tipo_instalacion: this.proyectoSeleccionado
                .nombre_tipo_instalacion,
            };
          }
          this.tipoInstalacionesCargando = false;
        })
        .catch((error) => {
          this.tipoInstalacionesCargando = false;
        });
    },
    obtenerTipoClientesProyecto() {
      this.tipoClientesCargando = true;
      this.obtenerTipoClientes()
        .then((res) => {
          this.tipoClientes = res.body;
          if (this.proyectoSeleccionado.tipo_cliente_id != null) {
            this.$v.formInformacionTecnica.tipoCliente.$model = {
              tipo_cliente_id: this.proyectoSeleccionado.tipo_cliente_id,
              nombre_tipo_cliente: this.proyectoSeleccionado
                .nombre_tipo_cliente,
            };
          }
          this.tipoClientesCargando = false;
        })
        .catch((error) => {
          this.tipoClientesCargando = false;
        });
    },
    obtenerAppFlexEnergeticasProyecto() {
      this.appFlexEnergeticasCargando = true;
      this.obtenerAppFlexEnergeticas()
        .then((res) => {
          this.appFlexEnergeticas = res.body;
          if (this.proyectoSeleccionado.app_flex_energetica_id != null) {
            this.$v.formInformacionTecnica.appFlexEnergetica.$model = {
              app_flex_energetica_id: this.proyectoSeleccionado
                .app_flex_energetica_id,
              nombre_app_flex_energetica: this.proyectoSeleccionado
                .nombre_app_flex_energetica,
            };
          }
          this.appFlexEnergeticasCargando = false;
        })
        .catch((error) => {
          this.appFlexEnergeticasCargando = false;
        });
    },
    obtenerRegionesProyecto() {
      this.regionesCargando = true;
      this.obtenerRegiones()
        .then((res) => {
          this.regiones = res.body;
          if (this.proyectoSeleccionado.codigo_comuna != null) {
            this.$v.form.comuna.$model = {
              codigo: this.proyectoSeleccionado.codigo_comuna,
              nombre: this.proyectoSeleccionado.nombre_comuna,
            };
          }
          if (this.proyectoSeleccionado.codigo_region != null) {
            this.$v.form.region.$model = {
              codigo: this.proyectoSeleccionado.codigo_region,
              nombre: this.proyectoSeleccionado.nombre_region,
            };
          }

          this.regionesCargando = false;
        })
        .catch((error) => {
          this.regionesCargando = false;
        });
    },
    obtenerComunasRegionProyecto() {
      this.comunasRegionCargando = true;
      this.$v.form.comuna.$model = null;
      let codigoRegion = this.$v.form.region.$model.codigo;
      this.obtenerComunasRegion(codigoRegion)
        .then((res) => {
          this.comunasRegion = res.body;
          this.comunasRegionCargando = false;
        })
        .catch((error) => {
          this.comunasRegionCargando = false;
        });
    },
    obtenerTipoProyectosProyecto() {
      this.tipoProyectosCargando = true;
      this.obtenerTipoProyectos()
        .then((res) => {
          this.tipoProyectos = res.body;
          if (this.proyectoSeleccionado.tipo_proyecto_id != null) {
            this.$v.form.tipoProyecto.$model = {
              tipo_proyecto_id: this.proyectoSeleccionado.tipo_proyecto_id,
              nombre_tipo_proyecto: this.proyectoSeleccionado
                .nombre_tipo_proyecto,
            };
          }
          this.tipoProyectosCargando = false;
        })
        .catch((error) => {
          this.tipoProyectosCargando = false;
        });
    },
    obtenerTipoVentasProyecto() {
      this.tipoVentasCargando = true;
      this.obtenerTipoVentas()
        .then((res) => {
          this.tipoVentas = res.body;
          if (this.proyectoSeleccionado.tipo_venta_id != null) {
            this.$v.form.tipoVenta.$model = {
              tipo_venta_id: this.proyectoSeleccionado.tipo_venta_id,
              nombre_tipo_venta: this.proyectoSeleccionado.nombre_tipo_venta,
            };
          }
          this.tipoVentasCargando = false;
        })
        .catch((error) => {
          this.tipoVentasCargando = false;
        });
    },
    obtenerEtapaProyectoProyecto() {
      this.etapasProyectoCargando = true;
      this.obtenerEtapasProyecto()
        .then((res) => {
          this.etapasProyecto = res.body;
          if (this.proyectoSeleccionado.etapa_proyecto_id != null) {
            this.$v.form.etapaProyecto.$model = {
              etapa_proyecto_id: this.proyectoSeleccionado.etapa_proyecto_id,
              nombre_etapa_proyecto: this.proyectoSeleccionado
                .nombre_etapa_proyecto,
            };
          }
          this.etapasProyectoCargando = false;
        })
        .catch((error) => {
          this.etapasProyectoCargando = false;
        });
    },
    obtenerSubEtapaProyectoProyecto() {
      this.subEtapasProyectoCargando = true;
      this.obtenerSubEtapasProyecto()
        .then((res) => {
          this.subEtapasProyecto = res.body;
          if (this.proyectoSeleccionado.sub_etapa_proyecto_id != null) {
            this.$v.form.subEtapaProyecto.$model = {
              sub_etapa_proyecto_id: this.proyectoSeleccionado
                .sub_etapa_proyecto_id,
              nombre_sub_etapa_proyecto: this.proyectoSeleccionado
                .nombre_sub_etapa_proyecto,
            };
          }
          this.subEtapasProyectoCargando = false;
        })
        .catch((error) => {
          this.subEtapasProyectoCargando = false;
        });
    },
    obtenerProyectosUsuario() {
      this.proyectosCargando = true;
      this.obtenerProyectos()
        .then((res) => {
          this.proyectos = res.body;
          this.proyectosFiltrados = res.body;

          this.proyectosCargando = false;
        })
        .catch((error) => {
          this.proyectosCargando = false;
        });
    },
    openModalInputPanelIfOptionIsOther(value){
      if(value == 'Otro'){
        let self = this;
        this.$swal({
          text: "Ingresa el nombre del tablero personalizado",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
          input: "textarea",
          inputPlaceholder: "Tablero Personalizado",
          inputValue: "",
          inputAttributes: {
            class: "mt-3",
          },
        }).then(async (result) => {
          if (!result.dismiss) {
            const value = result.value;
            if (!value) {
              self.$swal({
                icon: "error",
                title: "Error",
                text:
                  "El nombre del panel es requerido",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });              
              self.form.panel_especial = null
              return;
            }
            self.setNewSpecialPanel(value)
            self.form.panel_especial = value
          }else{
              self.form.panel_especial = null
              return;
          }
        });
      }
  },
  submit(e) {
      this.loading = true;
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.loading = false;
        return;
      }

      const codigo_proyecto = this.$v.form.codigoProyecto.$model;
      const nombre_proyecto = this.$v.form.nombreProyecto.$model;
      const etapa_proyecto_id = this.$v.form.etapaProyecto.$model
        .etapa_proyecto_id;
      const nombre_etapa_proyecto = this.$v.form.etapaProyecto.$model != null ? 
      this.$v.form.etapaProyecto.$model.nombre_etapa_proyecto : null;
      const sub_etapa_proyecto_id = null;
      const nombre_sub_etapa_proyecto = null;

      const imagen = "866a79ba5c54d73644efbfc0d3e3a3f5.jpg";

      const monto_proyecto = this.$v.form.montoProyecto.$model;

      const tipo_proyecto_id = this.$v.form.tipoProyecto.$model != null ?
        this.$v.form.tipoProyecto.$model.tipo_proyecto_id : null;
      const nombre_tipo_proyecto = this.$v.form.tipoProyecto.$model != null ?
        this.$v.form.tipoProyecto.$model.nombre_tipo_proyecto : null;

      const tipo_venta_id =
        this.$v.form.tipoVenta.$model != null
          ? this.$v.form.tipoVenta.$model.tipo_venta_id
          : null;
      const nombre_tipo_venta =
        this.$v.form.tipoVenta.$model != null
          ? this.$v.form.tipoVenta.$model.nombre_tipo_venta
          : null;

      const estado = "Activo";
      const direccion = this.$v.form.direccion.$model;
      const panel_especial = this.$v.form.panel_especial.$model;

      const codigo_comuna = this.$v.form.comuna.$model != null ? this.$v.form.comuna.$model.codigo : null;
      const nombre_comuna = this.$v.form.comuna.$model != null ? this.$v.form.comuna.$model.nombre : null;

      const codigo_region = this.$v.form.region.$model != null ? this.$v.form.region.$model.codigo : null;
      const nombre_region = this.$v.form.region.$model != null ? this.$v.form.region.$model.nombre : null;
      let latitud = this.$v.form.latitud.$model;
      let longitud = this.$v.form.longitud.$model;
      const zona =
        this.$v.form.zona.$model != null
          ? this.$v.form.zona.$model.value
          : null;

      latitud = latitud != null ? latitud.toString().replace(",", ".") : null;
      longitud =
        longitud != null ? longitud.toString().replace(",", ".") : null;

      //Informacion tecnica

      const potencia_peak = this.proyectoSeleccionado.potencia_peak;
      const potencia_ac = this.proyectoSeleccionado.potencia_ac;
      const capacidad_baterias = this.proyectoSeleccionado.capacidad_baterias;
      const capacidad_empalme = this.proyectoSeleccionado.capacidad_empalme;
      const tipo_instalacion_id =
        this.proyectoSeleccionado.tipo_instalacion_id != null
          ? this.proyectoSeleccionado.tipo_instalacion_id
          : null;
      const nombre_tipo_instalacion =
        this.proyectoSeleccionado.nombre_tipo_instalacion != null
          ? this.proyectoSeleccionado.nombre_tipo_instalacion
          : null;

      const fase_instalacion = this.proyectoSeleccionado.fase_instalacion;
      const tipo_cliente_id =
        this.proyectoSeleccionado.tipo_cliente_id != null
          ? this.proyectoSeleccionado.tipo_cliente_id
          : null;
      const nombre_tipo_cliente =
        this.proyectoSeleccionado.nombre_tipo_cliente != null
          ? this.proyectoSeleccionado.nombre_tipo_cliente
          : null;
      const normativa_id =
        this.proyectoSeleccionado.normativa_id != null
          ? this.proyectoSeleccionado.normativa_id
          : null;
      const nombre_normativa =
        this.proyectoSeleccionado.nombre_normativa != null
          ? this.proyectoSeleccionado.nombre_normativa
          : null;
      const cliente_electrico = this.proyectoSeleccionado.cliente_electrico;
      const app_flex_energetica_id =
        this.proyectoSeleccionado.app_flex_energetica_id != null
          ? this.proyectoSeleccionado.app_flex_energetica_id
          : null;
      const nombre_app_flex_energetica =
        this.proyectoSeleccionado.nombre_app_flex_energetica != null
          ? this.proyectoSeleccionado.nombre_app_flex_energetica
          : null;

      if (this.esEditar) {
        this.actualizarProyecto({
          codigo_proyecto,
          nombre_proyecto,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          imagen,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          panel_especial,
          tipo_venta_id,
          nombre_tipo_venta,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Proyecto actualizado correctamente!!!";
              this.mostrarModalEditarProyecto = false;
              this.obtenerProyectoUsuario();
            }
            this.segundosMensajeActualizacion = this.segundos;

            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModalEditarProyecto = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
      }
      this.submitted = false;
    },
    submitInformacionTecnica(e) {
      this.loading = true;
      this.submitted = true;
      this.$v.formInformacionTecnica.$touch();

      if (this.$v.formInformacionTecnica.$invalid) {
        this.loading = false;
        return;
      }

      const codigo_proyecto = this.proyectoSeleccionado.codigo_proyecto;
      const nombre_proyecto = this.proyectoSeleccionado.nombre_proyecto;
      const etapa_proyecto_id = this.proyectoSeleccionado.etapa_proyecto_id;
      const nombre_etapa_proyecto = this.proyectoSeleccionado
        .nombre_etapa_proyecto;
      const sub_etapa_proyecto_id = this.proyectoSeleccionado
        .sub_etapa_proyecto_id;
      const nombre_sub_etapa_proyecto = this.proyectoSeleccionado
        .nombre_sub_etapa_proyecto;
      const imagen = "866a79ba5c54d73644efbfc0d3e3a3f5.jpg";

      const monto_proyecto = this.proyectoSeleccionado.monto_proyecto;

      const tipo_proyecto_id = this.proyectoSeleccionado.tipo_proyecto_id;
      const nombre_tipo_proyecto = this.proyectoSeleccionado
        .nombre_tipo_proyecto;

      const tipo_venta_id =
        this.proyectoSeleccionado.tipo_venta_id != null
          ? this.proyectoSeleccionado.tipo_venta_id
          : null;
      const nombre_tipo_venta =
        this.proyectoSeleccionado.nombre_tipo_venta != null
          ? this.proyectoSeleccionado.nombre_tipo_venta
          : null;

      const estado = "Activo";
      const direccion = this.proyectoSeleccionado.direccion;
      const panel_especial = this.proyectoSeleccionado.panel_especial;
      const codigo_comuna = this.proyectoSeleccionado.codigo_comuna;
      const nombre_comuna = this.proyectoSeleccionado.nombre_comuna;

      const codigo_region = this.proyectoSeleccionado.codigo_region;
      const nombre_region = this.proyectoSeleccionado.nombre_region;
      let latitud = this.proyectoSeleccionado.latitud;
      let longitud = this.proyectoSeleccionado.longitud;
      const zona = this.proyectoSeleccionado.zona;

      latitud = latitud != null ? latitud.toString().replace(",", ".") : null;
      longitud =
        longitud != null ? longitud.toString().replace(",", ".") : null;

      //Informacion tecnica

      const potencia_peak = this.$v.formInformacionTecnica.potenciaPeak.$model;
      const potencia_ac = this.$v.formInformacionTecnica.potenciaAC.$model;
      const capacidad_baterias = this.$v.formInformacionTecnica
        .capacidadBaterias.$model;
      const capacidad_empalme = this.$v.formInformacionTecnica.capacidadEmpalme
        .$model;
      const tipo_instalacion_id =
        this.$v.formInformacionTecnica.tipoInstalacion.$model != null
          ? this.$v.formInformacionTecnica.tipoInstalacion.$model
              .tipo_instalacion_id
          : null;
      const nombre_tipo_instalacion =
        this.$v.formInformacionTecnica.tipoInstalacion.$model != null
          ? this.$v.formInformacionTecnica.tipoInstalacion.$model
              .nombre_tipo_instalacion
          : null;

      const fase_instalacion = this.$v.formInformacionTecnica.faseInstalacion
        .$model.value;
      const tipo_cliente_id =
        this.$v.formInformacionTecnica.tipoCliente.$model != null
          ? this.$v.formInformacionTecnica.tipoCliente.$model.tipo_cliente_id
          : null;
      const nombre_tipo_cliente =
        this.$v.formInformacionTecnica.tipoCliente.$model != null
          ? this.$v.formInformacionTecnica.tipoCliente.$model
              .nombre_tipo_cliente
          : null;
      const normativa_id =
        this.$v.formInformacionTecnica.normativa.$model != null
          ? this.$v.formInformacionTecnica.normativa.$model.normativa_id
          : null;
      const nombre_normativa =
        this.$v.formInformacionTecnica.normativa.$model != null
          ? this.$v.formInformacionTecnica.normativa.$model.nombre_normativa
          : null;
      const cliente_electrico = this.$v.formInformacionTecnica.clienteElectrico
        .$model.value;
      const app_flex_energetica_id =
        this.$v.formInformacionTecnica.appFlexEnergetica.$model != null
          ? this.$v.formInformacionTecnica.appFlexEnergetica.$model
              .app_flex_energetica_id
          : null;
      const nombre_app_flex_energetica =
        this.$v.formInformacionTecnica.appFlexEnergetica.$model != null
          ? this.$v.formInformacionTecnica.appFlexEnergetica.$model
              .nombre_app_flex_energetica
          : null;

      if (this.esEditar) {
        this.actualizarProyecto({
          codigo_proyecto,
          nombre_proyecto,
          etapa_proyecto_id,
          nombre_etapa_proyecto,
          sub_etapa_proyecto_id,
          nombre_sub_etapa_proyecto,
          imagen,
          monto_proyecto,
          tipo_proyecto_id,
          nombre_tipo_proyecto,
          estado,
          panel_especial,
          tipo_venta_id,
          nombre_tipo_venta,
          direccion,
          codigo_comuna,
          nombre_comuna,
          codigo_region,
          nombre_region,
          latitud,
          longitud,
          zona,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Proyecto actualizado correctamente!!!";
              this.mostrarModalEditarInformacionTecnica = false;
              this.obtenerProyectoUsuario();
            }
            this.segundosMensajeActualizacion = this.segundos;

            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModalEditarInformacionTecnica = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        codigoProyecto: null,
        nombreProyecto: null,
        etapaProyecto: null,
        subeEtapaProyecto: null,
        fechaCreacion: null,
        imagen: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        direccion: null,
        panel_especial: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    urlImagen(imagen) {
      if (imagen == null) {
        return `${process.env.VUE_APP_URL_IMAGENES_LUGARES_INSTALACION}lugarInstalacion.jpg`;
      }
      return `${process.env.VUE_APP_URL_IMAGENES_LUGARES_INSTALACION}${imagen}`;
    },
    imageUrlError(event) {
      event.target.src = `${process.env.VUE_APP_URL_IMAGENES_LUGARES_INSTALACION}lugarInstalacion.jpg`;
    },
    obtenerLugaresInstalacionProyecto() {
      this.loading = true;
      if (this.verTodosLugares) {
        this.obtenerLugaresInstalacion(this.codigoProyecto)
          .then((res) => {
            this.lugaresInstalacion = res.body;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.obtenerLugaresInstalacionContacto({
          codigoProyecto: this.codigoProyecto,
          emailContacto: this.user.email,
        })
          .then((res) => {
            this.lugaresInstalacion = res.body;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    abrirMenu(lugarInstalacion) {
      this.lugarInstalacion = lugarInstalacion;
      this.$bvModal.show("modal-menu-lugares-instalacion");
    },
    verMateriales(lugarInstalacion) {
      if(this.$route.query.sap_project){
        this.$router.push({
          name: "sapRequestMaterials",
          query: {
            sap_project: this.$route.query.sap_project,
          },
       });
      }else {
        this.$router.push({
          name: "solicitudMateriales",
          params: {
            codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: lugarInstalacion.codigo_proyecto,
          },
        });
      }
    },
    verMas(lugarInstalacion) {
      this.$router.push({
        name: "informacionCompleta",
        params: {
          codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verGaleriaImagenes(lugarInstalacion) {
      this.$router.push({
        name: "galeriaImagenes",
        params: {
          codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verDocumentos(lugarInstalacion) {
      this.$router.push({
        name: "documentos",
        params: {
          codigoLugarInstalacion: lugarInstalacion
            .codigo_lugar_instalacion,
          codigoProyecto: lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verGantt(lugarInstalacion) {
      this.$router.push({
        name: "gantt",
        params: {
          codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: lugarInstalacion.codigo_proyecto,
        },
      });
    },
    abrirModalNuevoWizar() {
      this.mostrarModalWizar = true;
    },
    cerrarWizar() {
      this.obtenerLugaresInstalacionProyecto();
      this.mostrarModalWizar = false;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("es-CL", {
          style: "currency",
          currency: "CLP",
        });
      return;
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...proyectoComputed,
    ...mapGetters({
      specialPanels: 'project/specialPanels'
    })
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
.card-body-lugares {
  background-color: #f7f7f7;
}
.col-informacion {
  width: 50%;
  float: left;
}
@media (max-width: 1500px) {
  .d-flex {
    display: block !important;
  }
  .col-informacion {
    width: 100%;
    float: none;
    display: block !important;
  }
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>

<style>
.btn.dropdown-toggle {
  color: #fff;
}
.titulo-lugar-instalacion {
  max-width: 60%;
  height: 40px;
  font-weight: 500;
}
.contenedor-menu-escritorio-lugares-instalacion {
  /* position: relative; */
  /* top: -20px; */
}

.menu-escritorio-lugares-instalacion {
  position: absolute;
  right: 10px;
  margin-top: -10px;
}

.menu-escritorio-one-lugar .menu-escritorio-lugares-instalacion {
  position: relative !important;
  right: auto !important;
  margin-top: 0 !important;
  float: right !important;
}

.contenedor-menu-movil-lugares-instalacion {
  position: absolute;
  right: 10px;
  display: none;
  cursor: pointer;
  margin-top: -15px;
}
.contenedor-menu-movil-lugares-instalacion i {
  font-size: 1.8rem;
}
.proyecto-contenedor {
  /* cursor: pointer; */
  /* height: 280px;
    margin-bottom: 20px; */
}

.proyecto-contenedor .card {
  box-shadow: 0 0.75rem 1.5rem #ddd;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.proyecto-contenedor .card:hover {
  box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.51);
}
.label-proyecto-imagen {
  margin: 0;
}
.label-proyecto-imagen {
  width: 37%;
  display: inline-block;
  height: 200px;
}
.proyecto-imagen {
  width: 100%;
  display: inline-block;
  height: 100%;
  position: relative;
}
.proyecto-imagen img {
  /* max-width: 300px;
  min-width: 300px;
  max-height: 200px;
  min-height: 200px; */

  width: 100%;
  height: 112%;
  object-fit: cover;
}

.proyecto-cuerpo {
  /* position: relative; */

  /* height: 195px; */
  /* width: 100%; */
  width: 63%;
  display: inline-block;
  padding-left: 15px;
}

.proyecto-cuerpo-direccion {
  font-size: 11px;
  top: -6px;
  position: relative;
  padding-left: 15px;
}

.proyecto-pie {
  position: absolute;
  bottom: 10px;
}

.proyecto-pie li {
  cursor: pointer;
}

.proyecto-pie li span:hover {
  color: #556ee6 !important;
}

.proyecto-pie li i {
  color: #999;
}
.proyecto-pie li span {
  top: -3px;
  position: relative;
  color: #999;
}

.menu-separador {
  font-size: 22px;
  color: #ccc;
}

@media (max-width: 576px) {
  .label-proyecto-imagen {
    width: 100%;
    display: block;
    height: auto;
  }
  /* .proyecto-imagen {
        width: 100%;
        display: block;
        height: auto;
    } */
  .proyecto-imagen img {
    width: 100%;
    height: 100%;

    max-width: none;
    min-width: none;
    max-height: none;
    min-height: none;
  }

  .proyecto-contenedor {
    height: auto;
    margin-bottom: 0;
  }
  .proyecto-cuerpo {
    width: 100%;

    padding: 20px;
    height: auto;
    display: block;
  }
  .proyecto-pie {
    position: relative;
    margin-top: 20px;
  }

  .contenedor-menu-escritorio-lugares-instalacion {
    display: block;
  }
  .contenedor-menu-movil-lugares-instalacion {
    display: none;
  }
  .proyecto-cuerpo-direccion-contenedor {
    margin-top: 30px;
  }
}

.icono {
  float: left;
  font-size: 1.2rem;
  margin-top: 10px;
  color: #555;
}
.contenedor-textos {
  margin-left: 50px;
}

.input-file-imagen {
  display: none;
}

@media (max-width: 1024px) {
  .boton-lugar-instalacion {
    margin-top: 10px;
    width: 100%;
  }
  .boton-lugar-instalacion button {
    width: 100%;
  }
}
@media (max-width: 574px) {
  .titulo-lugar-instalacion {
    margin-top: 50px;
  }
}

.contenedor-botones-lugar {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1000;
}
.mostrar-botones {
  z-index: 999999;
}
.ocultar-botones {
  z-index: -1000;
}
</style>
